import React from 'react';
import {BiLink} from 'react-icons/bi';
import {FaFacebook, FaLinkedin, FaRegBookmark, FaTwitter} from 'react-icons/fa';

const ICON_SIZE = 18;

function ShareButton({...props}) {
  return <div className="cursor-pointer hover:text-foreground" {...props} />;
}

async function copyToClipboard(str: string) {
  return navigator.clipboard.writeText(window?.location.href).then(() => {
    window.alert(`${str} kopierat till urklipp`);
  });
}

export function ShareButtons() {
  return (
    <div className="flex justify-between space-x-5 text-dark-gray lg:justify-end align-center">
      <ShareButton onClick={async () => await copyToClipboard(window?.location.href)}>
        <BiLink size={ICON_SIZE + 2} />
        <span className="sr-only">Kopiera länk</span>
      </ShareButton>
      <ShareButton>
        <FaTwitter size={ICON_SIZE} />
        <span className="sr-only">Dela på Twitter</span>
      </ShareButton>
      <ShareButton>
        <FaFacebook size={ICON_SIZE} />
        <span className="sr-only">Dela på Facebook</span>
      </ShareButton>
      <ShareButton>
        <FaLinkedin size={ICON_SIZE} />
        <span className="sr-only">Dela på LinkedIn</span>
      </ShareButton>
      <ShareButton>
        <FaRegBookmark size={18} />
        <span className="sr-only">Bokmärk</span>
      </ShareButton>
    </div>
  );
}
