import {gql, useQuery} from '@apollo/client';
import {ArticleType} from '@/types/article';

export const GET_ARTICLES = gql`
  query articles($start: Int!, $limit: Int!) {
    articles(start: $start, limit: $limit, sort: "published_at:desc") {
      id
      title
      excerpt
      companies(limit: 3) {
        id
        name
        logo {
          id
          url
          width
          height
        }
      }
      categories(limit: 5) {
        id
        slug
        title
      }
      tags(limit: 5) {
        id
        title
        slug
      }
      article_url
      featured
      sponsored
      #body
      slug
      date
      created_at
      published_at
      updated_at
      source
      image {
        id
        url
        width
        height
      }
    }
  }
`;

type GetArticlesVars = {start?: number; limit?: number};

export const getArticlesVars: GetArticlesVars = {start: 0, limit: 9};

export function useGetArticles(variables?: GetArticlesVars) {
  return useQuery<{articles: ArticleType[]}, GetArticlesVars>(GET_ARTICLES, {
    variables: {...getArticlesVars, ...variables},
    notifyOnNetworkStatusChange: true,
  });
}

export function useGetArticlesWitoutBody(variables?: GetArticlesVars) {
  return useQuery<{articles: ArticleType[]}, GetArticlesVars>(GET_ARTICLES_WITHOUT_BODY, {
    variables: {...getArticlesVars, ...variables},
    notifyOnNetworkStatusChange: true,
  });
}
export const GET_ARTICLES_WITHOUT_BODY = gql`
  query articles($start: Int!, $limit: Int!) {
    articles(start: $start, limit: $limit, sort: "published_at:desc") {
      id
      title
      excerpt
      companies(limit: 3) {
        id
        name
        logo {
          id
          url
          width
          height
        }
      }
      categories(limit: 5) {
        id
        slug
        title
      }
      tags(limit: 5) {
        id
        title
        slug
      }
      featured
      sponsored
      slug
      created_at
      published_at
      updated_at
      date
      source
      image {
        id
        url
        width
        height
      }
    }
  }
`;
