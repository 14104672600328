import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Markdown from 'react-markdown';
import Zoom from 'react-medium-image-zoom';
import rehypeRaw from 'rehype-raw';

import {ArticleCategories} from '@/components/article/article-categories';
import {ShareButtons} from '@/components/share-buttons';
import {getImageUrl, readingTime, trimLength} from '@/lib/utils';
import {ArticleType} from '@/types/article';
import rehypeExternalLinks from 'rehype-external-links';
import {DateFormatter} from '../date-formatter';
import {ArticleSkeleton} from './article-skeleton';
import {CompanyAvatar} from './company-avatar';
import {useGetArticles} from '@/lib/api/getArticles';
import {Article} from './article';

import 'react-medium-image-zoom/dist/styles.css';

const MaybeLink = ({slug, children}: {slug: string; children: React.ReactElement}) => {
  if (!slug) return children;
  return (
    <Link href={`/nyheter/${slug}`}>
      <a>{children}</a>
    </Link>
  );
};

type ArticleFullProps = {
  article: ArticleType;
  isPreview?: boolean;
};

const ZoomOrLink = ({slug, children}: {slug: string; children: React.ReactElement}) => {
  if (slug) return <MaybeLink slug={slug}>{children}</MaybeLink>;
  return (
    <Zoom
      overlayBgColorStart="rgba(var(--color-background), 0.0)"
      overlayBgColorEnd="rgba(var(--color-background), 0.90)"
    >
      {children}
    </Zoom>
  );
};

export function ArticleFull({article, isPreview = false}: ArticleFullProps) {
  const {title, body, image, categories, tags} = article;
  const date = article?.date || article?.published_at || article.created_at;
  const isArticleUpdated =
    new Date(article.updated_at).getTime() - new Date(date).getTime() > 6 * 60 * 1000; // 6 minutes
  const showFeaturedImage = image && body?.indexOf('![](') === -1;
  const readTime = readingTime(body) ?? '-';
  const {data: recentArticleData} = useGetArticles({limit: 4});

  if (!article) {
    return (
      <div className="container w-full max-w-3xl mx-auto mb-4">
        <ArticleSkeleton main />
        <ArticleSkeleton main />
      </div>
    );
  }

  const markdownBody = (
    isPreview ? trimLength(article.excerpt || body, 999) : body.replace(/\----+/g, '') && body
  ).replace('noWrap', 'nowrap');

  // min-w-0 is important
  return (
    <div className="relative flex flex-col items-center min-w-0 mx-auto mb-6">
      <div className="container w-full max-w-screen-xl">
        <div className="text-xs font-bold">
          <ArticleCategories categories={categories} />
        </div>
        <div className="mt-2">
          <MaybeLink slug={isPreview ? article.slug : ''}>
            <h1 className="inline text-3xl font-bold sm:text-5xl text-foreground">{title}</h1>
          </MaybeLink>
        </div>

        {/* Date and Read Time */}
        <div className="my-2 text-xs ">
          <div className="inline-flex items-center">
            <DateFormatter dateString={date} variant="longTime" />
            {body && (
              <>
                <svg width="2" height="2" fill="currentColor" className="mx-1.5" aria-hidden="true">
                  <circle cx="1" cy="1" r="1" />
                </svg>{' '}
                {readTime} min läsning
              </>
            )}
          </div>

          {isArticleUpdated && (
            <div className="mt-1 italic">
              <span className="font-bold">Uppdaterad:</span>{' '}
              <DateFormatter dateString={article.updated_at} variant="longTime" />
            </div>
          )}
        </div>

        {/* Company + Source */}
        <div className="flex flex-wrap-reverse items-center justify-between w-full mb-3">
          <CompanyAvatar source={article.source} companies={article.companies} size="lg" />
          <div className="py-4">
            <ShareButtons />
          </div>
        </div>
      </div>

      {/* Featured Image */}
      <div className="relative mb-8 md:container md:max-w-screen-xl">
        {showFeaturedImage ? (
          <ZoomOrLink slug={isPreview ? article.slug : ''}>
            <div className="relative max-w-[1400px] flex max-h-[900px]">
              <Image
                objectFit="contain"
                src={getImageUrl(image.url)}
                alt={title}
                width={image.width}
                height={image.height}
                priority
              />
              {article.sponsored && (
                <span className="text-[10px] dark text-background font-semibold bg-white/60 right-0 bottom-0 px-1 rounded-sm absolute">
                  Annons
                </span>
              )}
            </div>
          </ZoomOrLink>
        ) : (
          <div className="h-px bg-border" />
        )}
      </div>
      <div className="container grid max-w-screen-xl grid-cols-12">
        {/* Body column */}
        <div className="flex justify-center lg:col-span-8 sm:px-0 col-span-full">
          <div>
            <Markdown
              rehypePlugins={[rehypeExternalLinks, rehypeRaw]}
              className="text-lg prose break-words dark:prose-invert"
            >
              {markdownBody}
            </Markdown>
            {/* Read more link */}
            {isPreview && (
              <Link href={`/nyheter/${article.slug}`}>
                <a className="inline-block mt-2 font-semibold underline text-dark-gray hover:text-foreground">
                  Läs hela artikeln
                </a>
              </Link>
            )}
            {/* Tags */}
            {!!tags?.length && (
              <div className="pt-4 my-4 space-x-3 border-t">
                {(tags || []).slice(0, 5).map((tag) => (
                  <Link href={`/tag/${tag.slug}`} key={tag.id}>
                    <a className="inline-flex">
                      <span className="px-2 py-1 leading-tight text-xs font-semibold rounded-full dark:text-[#d9f7ec] dark:bg-primary text-primary-700 bg-primary-100 last:mr-0">
                        {tag.title}
                      </span>
                    </a>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* Sidebar */}
        {!isPreview && (
          <div className="lg:col-start-10 lg:col-span-4 col-span-full">
            <h4 className="text-xl font-bold">Senaste nyheterna</h4>
            <ol className="grid-cols-1 gap-8 lg:grid lg:grid-cols-1 border-border-light lg:gap-0">
              {recentArticleData?.articles.map((article) => (
                <li key={article.id} className="border-b last:border-0">
                  <Article key={article.id} article={article} variant="small" showDate priority />
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
}
